import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from "firebase/messaging";
let firebaseConfig = {};

switch (process.env.VUE_APP_BACKEND_URL) {
    case 'local':
    case 'develop':
        firebaseConfig = {
            apiKey: "AIzaSyCiybTTelugvkWq9sDDeBYSpNlKzXuBrls",
            authDomain: "fdtigermaster-dev.firebaseapp.com",
            databaseURL: "https://fdtigermaster-dev.firebaseio.com",
            projectId: "fdtigermaster-dev",
            storageBucket: "fdtigermaster-dev.appspot.com",
            messagingSenderId: "16706967805",
            appId: "1:16706967805:web:6465537a3bf6787e4f0694",
            measurementId: "G-DS8Z1YM362"
        };
        break;
    case 'staging':
        firebaseConfig = {
            apiKey: "AIzaSyB85k_2HQhuuCnerUXO9AFowL1HMbU6omg",
            authDomain: "fdtigermaster-stg.firebaseapp.com",
            databaseURL: "https://fdtigermaster-stg.firebaseio.com",
            projectId: "fdtigermaster-stg",
            storageBucket: "fdtigermaster-stg.appspot.com",
            messagingSenderId: "73494613779",
            appId: "1:73494613779:web:f6da23941530db78cc94e6",
            measurementId: "G-M7BY01SB9D"
        };
        break;
    case 'production':
        firebaseConfig = {
            apiKey: "AIzaSyBMJcAkwDOkp9O5-L2j2zUSP6GFI9Ve2SM",
            authDomain: "fdtigermaster-pro.firebaseapp.com",
            databaseURL: "https://fdtigermaster-pro.firebaseio.com",
            projectId: "fdtigermaster-pro",
            storageBucket: "fdtigermaster-pro.appspot.com",
            messagingSenderId: "1042270059391",
            appId: "1:1042270059391:web:54576fc117afe41407b84a",
            measurementId: "G-0E15N0WWXB"
        };
        break;
}

const firebase = initializeApp(firebaseConfig);
export const getMessage = getMessaging();
export const messaging = onMessage;
export const tokenGetter = getToken;

export default firebase;
